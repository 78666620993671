$(document).ready(function(){
	let headerIndent;
	if ($(window).width() > 550) {
		headerIndent = 0;
	} else {
		headerIndent = 0;
	}
	$(window).on('scroll', function() {
		if ($(window).scrollTop() > headerIndent) {
			$('.header').addClass('header__active');
		} else {
			$('.header').removeClass('header__active');
		}
	});

	// mobile menu

	function getScrollBarWidth() {
		var $outer = $('<div>').css({ visibility: 'hidden', width: 100, overflow: 'scroll' }).appendTo('body'),
			widthWithScroll = $('<div>').css({ width: '100%' }).appendTo($outer).outerWidth();
		$outer.remove();

		return 100 - widthWithScroll;
	};

	$('.header__nav-link_burger').on('click', function() {
		$('.mobile__menu').toggleClass('open').animate({
			opacity: 1,
			height: "toggle",
		  }, 500, "linear");
		$('.mobile__menu-wrap').animate({
			opacity: 1,
		}, 1100, "linear");
		
		if($('.header').hasClass("header__invers")) {
			$('.header').toggleClass("header__invers");
			$('.header').removeClass("header__invers").addClass("return_invers");
		} else {
			if($('.header').hasClass("return_invers")) {
				$('.header').removeClass("return_invers").addClass("header__invers");
			}
		}

		$('html, body').toggleClass("frozen");

		if (window.innerWidth <= 800) {

			if ($('.mobile__menu').hasClass('open')) {
				$('.header').addClass('header__invers-logo header__invers-blue')
			} else {
				$('.header').removeClass('header__invers-logo header__invers-blue')
			}
		}

	});

	function closeMenu() {
		$('.mobile__menu').animate({
			opacity: 0,
			height: "toggle",
		  }, 500, "linear");
		$('.mobile__menu-wrap').animate({
			opacity: 0,
		}, 800, "linear");
	};

	$('.mobile-menu__close').on('click', function() {
		closeMenu()
	});

	$('.mobile__menu-link').on('click', function() {
		$('.header__nav-img_burger').toggleClass("header__nav-img_burger-close"),
		setTimeout(closeMenu(), 1000);

		if($('.header').hasClass("header__invers")) {
			$('.header').toggleClass("header__invers");
			$('.header').removeClass("header__invers").addClass("return_invers");
		} else {
			if($('.header').hasClass("return_invers")) {
				$('.header').removeClass("return_invers").addClass("header__invers");
			}
		}
		$('html, body').removeClass("frozen");
		$('body').css('padding-right', '0px');
	});

	$(".header__nav-img_burger").click(function () {
		$('.header__nav-img_burger').toggleClass("header__nav-img_burger-close");
		$('body').css('padding-right', getScrollBarWidth() + 'px');
		$('.mobile__menu').css('padding-right', getScrollBarWidth() + 'px');
		$('.header__active').css('padding-right', getScrollBarWidth() + 'px');
	});

	$(".header__nav-img_burger-close").click(function () {
		$('body').css('padding-right','0px');
		$('.mobile__menu').css('padding-right', '0px');
		$('.header__active').css('padding-right', '0px');
	});

});

